// import * as api from './processing-list-api'
import { mapState, mapMutations } from 'vuex'
import { timeFilter } from '@/utils/utils'
import orderOperation from '@/mixins/order-operation.js'
import { Dialog, Popup, Picker  } from 'vant';
export default {

    mixins: [ orderOperation ],
    components: {
        [Dialog.Component.name]: Dialog.Component,
        Popup,
        Picker,
    },
    data () {
        return {
            type: 'beingProcessedList',
            timeFilter,

            dialogVisible: false,   // dialog
            handOverPerson: {   // 移交人员
                cname: '',
                oppenId: '',
            },

            popupVisible: false,    // popup
            popupList: [
                { text: '杭州', disabled: true },
                { text: '宁波' },
                { text: '温州' },
            ],
            operItem: {}
        }
    },

    filters: {

    },

    computed: {
        ...mapState({
            untreatedList: s => s.untreatedList,
            beingProcessedList: s => s.beingProcessedList,
            processedList: s => s.processedList,
        })
    },

    mounted() {
        
        this.type = this.$route.params.type

        this.findRepairPersonnel()  // 查询人员列表
    },

    methods: {
        
        /* handle */
        toDetail (t) {
            this.$router.push('/processing-detail/' + t.creId)
        },

         /* handle */
         // popup
         onConfirm (val) {
            this.handOverPerson = val
            this.popupVisible = false

        },
        // dialog
        dialogConfirm () {  // 确认 - 移交对象

            let {
                cname: chandlerName,
                cwpId: chandlerId,

            } = this.handOverPerson

            let {
                creId
            } = this.processingDetail

            // mixins中
            this.editRepair({
                cstate: 1,
                creId,
                chandlerId,
                chandlerName,
            }, this.findByRepaireId)  
        },
    },
}