export function timeFilter ( time ) {
    let type
    if (typeof(time) == 'string' || !time) time = new Date(time)
    if (Object.keys(time).length) {
      type = time.type
      time = time.time
    }

    let year = time.getFullYear()
    let month = time.getMonth() + 1 < 10? "0" + (time.getMonth() + 1) : time.getMonth() + 1
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate()

    let hours = time.getHours() < 10 ? "0" + (time.getHours()) : time.getHours()
    let minutes = time.getMinutes() < 10 ? "0" + (time.getMinutes()) : time.getMinutes()
    let seconds = time.getSeconds() < 10 ? "0" + (time.getSeconds()) : time.getSeconds()

    if (type == 1) {
      return `${year}-${month}-${day}`
    } else {

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
    
  }

export function toTimestamp ( time ) {
  return 1
}